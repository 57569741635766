import { cloneElement, FunctionComponent, VNode } from "preact";
import { useFormContext } from "../FormContext";
import { useState } from "preact/hooks";
import classNames from "classnames";

import "./styles.scss";

export interface IFormItemProps {
  name: string;
  label?: string;
  trigger?: string;
  rules?: { validator: ((value: any) => boolean) | RegExp; message?: string; required?: boolean }[];
}

const FormItem: FunctionComponent<IFormItemProps> = ({
  name,
  label,
  children,
  trigger = "onChange",
}) => {
  const { formInstance } = useFormContext();
  const [value, setValue] = useState(formInstance.getValue(name));

  const controlledElement = cloneElement(children as VNode, {
    value,
    [trigger]: (newValue: any) => {
      console.log(newValue);
      formInstance.setValue(name, newValue);
      setValue(newValue);
    },
  });

  return (
    <div className="tp-form-item">
      {label && <div className="tp-form-item-label">{label}</div>}
      <div className={classNames("tp-form-item-content", { error: true })}>{controlledElement}</div>
    </div>
  );
};

export default FormItem;
