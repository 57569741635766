import { type StateCreator } from "zustand";
import type { HOST_MODE, ProductCompareItem } from "~/types";
import { RENDER_MODE } from "~/types";
import { BotStyleOptions, PlatformBrand } from "~/types";
import { setBotPosition as setBotPositionInCache } from "~/utils/storage";

export interface BotStore {
  botVisibleInWidgetMode?: boolean;
  resetBotVisibleInWidgetMode: () => void;
  toggleBotVisibilityInWidgetMode?: () => boolean;
  compareDialogVisible: boolean;
  compareData: ProductCompareItem[];
  toggleCompareDialog: () => void;
  setCompareData: (data: any) => void;
  removeCompareData: (product_id: string) => void;
  pushCompareData: (data: any) => void;
  integrationConfig: IntegrationConfig;
  updateIntegrationConfig: (config: Partial<IntegrationConfig>) => void;
  resetIntegrationConfig: () => void;
  getBotCode: () => string;
  rootRefresh: boolean;
  setRootRefresh: (data: boolean) => void;
  botPosition: Partial<Record<"right" | "bottom", number>>;
  setBotPosition: (pos: Partial<Record<"right" | "bottom", number>>) => void;
  resetBotPosition: () => void;
}

const defaultIntegrationConfig: IntegrationConfig = {
  renderMode: RENDER_MODE.FLOAT,
  platformConfig: {},
  platform: PlatformBrand.SELF,
};

export interface IntegrationConfig {
  code?: string;
  isDevMode?: boolean;
  isWidget?: boolean;
  hostMode?: HOST_MODE;
  renderMode?: RENDER_MODE;
  domain?: string;
  origin?: string;
  auto?: boolean;
  style?: BotStyleOptions;
  // 第三方对接平台，如 shopline, shopify 等
  platform?: PlatformBrand;
  platformConfig?: Record<string, any>;
}

const botStore: StateCreator<BotStore> = (set, get) => ({
  botVisibleInWidgetMode: false,
  resetBotVisibleInWidgetMode: () => {
    set(() => ({ botVisibleInWidgetMode: false }));
  },
  toggleBotVisibilityInWidgetMode: () => {
    set((state) => ({ botVisibleInWidgetMode: !state.botVisibleInWidgetMode }));
    return Boolean(get().botVisibleInWidgetMode);
  },
  compareDialogVisible: false,
  compareData: [] as ProductCompareItem[],
  rootRefresh: false,
  setRootRefresh: (data: boolean) => {
    set((state) => ({ rootRefresh: data }));
  },

  toggleCompareDialog: () => {
    set((state) => ({ compareDialogVisible: !state.compareDialogVisible }));
  },

  setCompareData: (data: any) => {
    set((state) => ({ compareData: data }));
  },

  removeCompareData: (product_id: string) => {
    set((state) => ({
      compareData: state.compareData.filter((item) => item.product_id !== product_id),
    }));
  },

  pushCompareData: (data: any) => {
    set((state) => ({ compareData: [...state.compareData, data] }));
  },

  // config 部分
  integrationConfig: defaultIntegrationConfig,

  updateIntegrationConfig: (config: Partial<IntegrationConfig>) => {
    set((state) => ({ integrationConfig: { ...state.integrationConfig, ...config } }));
  },
  resetIntegrationConfig: () => {
    set(() => ({ integrationConfig: defaultIntegrationConfig }));
  },
  getBotCode: () => get().integrationConfig.code || "",

  botPosition: {},
  setBotPosition: (pos) => {
    set(() => ({ botPosition: pos }));
    setBotPositionInCache(get().integrationConfig.code!, pos);
  },
  resetBotPosition: () => get().setBotPosition({}),
});

export default botStore;
