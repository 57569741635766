import { createContext } from "preact";
import { useContext } from "preact/hooks";

import FormInstance from "./FormInstance";

export interface FromContextValues {
  formInstance: FormInstance;
}

export const FromContext = createContext<FromContextValues>({} as FromContextValues);

export const useFormContext = () => useContext(FromContext);
