import { useEffect, useState } from "preact/compat";
import Icon from "@ant-design/icons";
import expandSVG from "~/assets/icons/expand.svg?react";
import arrowSVG from "~/assets/icons/arrow_02.svg?react";
import { useBotStore, useCustomizeStore } from "~/stores";
import { hexToRGBA } from "~/utils";
import { getRandomWelcomeTip } from "~/locales/welcome";
import { HOST_MODE } from "~/types";
import { getLocalAccessToken } from "~/utils/storage";

import "./index.scss";

interface IProps {
  name: string;
  color: string;
}

export default function BotHeader({ name }: IProps) {
  const {
    customize: { avatar, palettes },
  } = useCustomizeStore();

  const { integrationConfig, toggleBotVisibilityInWidgetMode } = useBotStore();
  const { hostMode, isWidget } = integrationConfig;

  const [tip, setTip] = useState("");

  function handleExpand() {
    if (integrationConfig.code) {
      const botDomain = `https://${import.meta.env.VITE_BOT_DOMAIN}`;
      const botLink = `${botDomain}/${integrationConfig.code}?origin=${encodeURIComponent(window.location.origin)}`;
      const botWindow = window.open(botLink, "_blank");

      let tokenSent = false;
      const sendToken = () =>
        botWindow?.postMessage(
          {
            type: "target-pilot-bot-token",
            code: integrationConfig.code,
            token: getLocalAccessToken(integrationConfig.code!),
          },
          botDomain
        );
      let sendTokenTimeout: NodeJS.Timeout | null = null;

      window.addEventListener("message", (e: MessageEvent) => {
        const {
          data: { type, code, origin },
        } = e;
        if (type === "target-pilot-bot-rendered" && origin === window.location.origin) {
          if (!tokenSent) {
            sendToken();
            sendTokenTimeout = setTimeout(() => sendToken, 1000);
          }
        }
        if (
          type === "target-pilot-bot-token-settled" &&
          origin === window.location.origin &&
          code === integrationConfig.code
        ) {
          tokenSent = true;

          if (sendTokenTimeout) {
            clearTimeout(sendTokenTimeout);
          }
        }
      });
    }
  }

  function handleMin() {
    toggleBotVisibilityInWidgetMode?.();
  }

  useEffect(() => {
    setTip(getRandomWelcomeTip());
  }, []);

  const prevRGBA = hexToRGBA(palettes[6], 0.1);

  return (
    <div
      className="bot-header-box"
      style={{
        background: `linear-gradient(${prevRGBA}, rgba(255, 255, 255, 0.2))`,
      }}
    >
      <div className="bot-header">
        <div className="avatar-box">
          {avatar && <img src={avatar} alt="" />}
          <div className="status"></div>
        </div>
        <div className="desc">
          <span className="name">{name}</span>
          <span className="tip">{tip}</span>
        </div>

        <div className="flex-grow"></div>
        {isWidget && hostMode === HOST_MODE.MOBILE && (
          <div className="min" onClick={handleMin}>
            <Icon component={arrowSVG} />
          </div>
        )}
        {((isWidget && hostMode !== HOST_MODE.MOBILE) || hostMode === HOST_MODE.IFRAME) && (
          <div className="expand" onClick={handleExpand}>
            <Icon component={expandSVG} />
          </div>
        )}
      </div>
    </div>
  );
}
