import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { useBotStore, useUserStore } from "~/stores";
import { trackingPackage } from "~/api/express";
import { CreatePackageMessageBody, TrackingResponse } from "~/types/express";
import TrackOrderTabs from "./TrackOrderTabs";
import ExpressProgress from "./ExpressProgress";
import { getConversationId } from "~/hooks/message/utils";
import { createPackageMessage } from "~/api/chat";
import StepLoading from "../LoadingMessage/StepLoading";

import "./styles.scss";

export interface IExpressCardProps {
  packageTracking?: CreatePackageMessageBody["metadata"]["packageTracking"];
}

const ExpressCard: FunctionComponent<IExpressCardProps> = ({ packageTracking }) => {
  const [data, setData] = useState<TrackingResponse | null>(null);
  const [status, setStatus] = useState<"initial" | "loading" | "success" | "error">("initial");
  const { info } = useUserStore();
  const { getBotCode } = useBotStore();

  const handleSubmit = async (values: any, skipPersist?: boolean) => {
    try {
      setStatus("loading");
      const res = await trackingPackage({
        ...values,
        ...info,
      });

      if (res) {
        setData(res);
        setStatus("success");
      } else {
        setStatus("error");
        return;
      }

      if (skipPersist) {
        return;
      }

      const conversationId = await getConversationId(getBotCode());

      createPackageMessage(conversationId, {
        ...info,
        content: "",
        messageFrom: "Bot",
        type: "PackageTracking",
        conversationId,
        metadata: { packageTracking: { ...values, trackingNumber: "" } },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (packageTracking) {
      handleSubmit(packageTracking, true);
    }
  }, []);

  const renderContent = () => {
    if (status === "error") {
      return "Order not found. Please verify the order number.";
    }

    if (status === "loading") {
      return (
        <div className="tp-express-card-loading">
          <StepLoading size="large" />
        </div>
      );
    }

    return data ? <ExpressProgress data={data} /> : <TrackOrderTabs onSubmit={handleSubmit} />;
  };

  return <div className="tp-express-card">{renderContent()}</div>;
};

export default ExpressCard;
