import { createContext } from "preact";
import { useContext } from "preact/hooks";
import { createStore, useStore, type StoreApi } from "zustand";
import botStore, { type BotStore } from "./bot";
import userStore, { type UserStore } from "./user";
import customizeStore, { type CustomizeStore } from "./customize";
import messageStore, { type MessageStore } from "./message";

export { default as botStore, type BotStore } from "./bot";
export { default as userStore, type UserStore } from "./user";
export { default as customizeStore, type CustomizeStore } from "./customize";
export { default as messageStore, type MessageStore } from "./message";

export interface Store {
  bot: StoreApi<BotStore>;
  user: StoreApi<UserStore>;
  customize: StoreApi<CustomizeStore>;
  message: StoreApi<MessageStore>;
}

export const StoreContext = createContext<Store | null>(null);

const defaultStore: Store = {
  bot: createStore(botStore),
  user: createStore(userStore),
  customize: createStore(customizeStore),
  message: createStore(messageStore),
};

export function useBotStore() {
  const store = useContext(StoreContext);
  return useStore((store || defaultStore).bot);
}

export function useUserStore() {
  const store = useContext(StoreContext);
  return useStore((store || defaultStore).user);
}

export function useCustomizeStore() {
  const store = useContext(StoreContext);
  return useStore((store || defaultStore).customize);
}

export function useMessageStore() {
  const store = useContext(StoreContext);
  return useStore((store || defaultStore).message);
}
