// Bot 使用的前置数据加载
import { usePrevious, useRequest } from "ahooks";
import { useEffect, useState } from "preact/compat";
import { PlatformBrand } from "~/types";
import { getAgentBotConfig, createUser } from "~/api/user";
import { syncShoplineUserInfo } from "~/api/integration";
import { addLocalBotCode, getLocalAccessToken, setLocalAccessToken } from "~/utils/storage";
import { useBotStore, useCustomizeStore, useUserStore } from "~/stores";
import { Customize } from "~/stores/customize";
import { BotConfigProps, generatePalettes, propsEqual } from "~/utils/widgetConfig";
import { getShoplineUserId } from "~/utils/shopline";

function getToken(code: string) {
  return new Promise(async (resolve, reject) => {
    let localAccessToken = getLocalAccessToken(code) ?? "";
    let access_token = localAccessToken;

    try {
      if (!localAccessToken) {
        const result = (await createUser(code)) as unknown as { access_token: string };
        access_token = result.access_token;
      }

      setLocalAccessToken(code, access_token);
      resolve(access_token);
    } catch (error) {
      reject(error);
    }
  });
}

export default function useAliasData(props: BotConfigProps) {
  const { code } = props;
  const prevProps = usePrevious(props);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tokenReady, setTokenReady] = useState(false);
  const { customize, updateCustomize } = useCustomizeStore();
  const { updateInfo: updateUserInfo } = useUserStore();
  const {
    rootRefresh,
    integrationConfig,
    setRootRefresh,
    resetBotVisibleInWidgetMode,
    resetIntegrationConfig,
  } = useBotStore();

  useEffect(() => {
    if (rootRefresh) {
      resetBotVisibleInWidgetMode();
      resetIntegrationConfig();
    }
  }, [rootRefresh]);

  // TODO:如果这里的 code 不对，要怎么处理？
  useRequest(() => getToken(code), {
    onSuccess() {
      setTokenReady(true);
    },
    onError() {
      setError(true);
    },
  });

  useEffect(() => {
    if (propsEqual(prevProps, props)) {
      return;
    }
    if (!loading) {
      const localCustomize: Partial<Customize> = {};
      if (props.avatar) {
        localCustomize.avatar = props.avatar;
      }
      if (props.name) {
        localCustomize.botName = props.name;
      }
      if (props.theme) {
        localCustomize.brandColor = props.theme;
        localCustomize.palettes = generatePalettes(props.theme);
      }
      if (props.welcome) {
        localCustomize.defaultMessageSetting = {
          ...(customize.defaultMessageSetting || {}),
          welcome: props.welcome,
        };
      }
      updateCustomize(localCustomize);
    }
  }, [loading, props, prevProps, customize]);

  useRequest(() => getAgentBotConfig(code), {
    async onSuccess(result) {
      const botConfig = result as any;
      const brandColor = props.theme || botConfig.brandColor;

      const recommendQuestions = botConfig.recommendQuestions.filter(
        (item: string) => item.trim() !== ""
      );

      if (props.welcome) {
        botConfig.defaultMessageSetting.welcome = props.welcome;
      }

      if (botConfig?.defaultMessageSetting?.placeHolder) {
        botConfig.defaultMessageSetting.placeholder = botConfig.defaultMessageSetting.placeHolder;
      }

      updateCustomize({
        avatar: props.avatar || botConfig.avatar,
        botName: props.name || botConfig.name,
        palettes: generatePalettes(brandColor),
        brandColor,
        recommendQuestions,
        defaultMessageSetting: botConfig.defaultMessageSetting,
        serviceStatus: botConfig.status,
        customerContact: botConfig.customerContact,
        logoRemoved: botConfig.logoRemoved,
      });

      const tenantId = botConfig.tenantId;

      updateUserInfo({
        tenantId,
        agentId: botConfig.id,
        tenantUnitId: botConfig.tenantUnitId,
      });

      addLocalBotCode(code);
      // 对接 shopline 用户信息
      if (integrationConfig.platform === PlatformBrand.SHOPLINE) {
        // TODO:调用对应的接口，同步用户信息
        syncShoplineUserInfo({
          botCode: code,
          tenantId,
          externalUserId: getShoplineUserId(),
        });
      }
    },
    onError() {
      setError(true);
    },
    onFinally() {
      setLoading(false);
      setRootRefresh(false);
    },
    ready: tokenReady,
    refreshDeps: [integrationConfig],
  });

  return {
    error,
    loading,
  };
}
