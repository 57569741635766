export const STORAGE_ACCESS_TOKEN = "ACCESS_TOKEN";
export const STORAGE_CONVERSATION_ID = "CONVERSATION_ID";
export const VIDEO_POPUP_SWITCH = "VIDEO_POPUP_SWITCH";

export const BOT_CODE = "BOT_CODE";
export const LAST_BOT_CODE = "LAST_BOT_CODE";
export const BOT_BUBBLE_MSG_RENDER_TS = "TP_BOT_BUBBLE_MSG_RENDER_TS";
export const BOT_WELCOME_MSG_RENDER_TS = "BOT_WELCOME_MSG_RENDER_TS";
export const BOT_POS = "TP_BOT_POS";

export function addLocalBotCode(code: string) {
  window.localStorage.setItem(LAST_BOT_CODE, code);
  const localBots = getLocalBotCode();
  if (!localBots.includes(code)) {
    localBots.push(code);
  }
  window.localStorage.setItem(BOT_CODE, JSON.stringify(localBots));
}

export function getLocalBotCode() {
  return JSON.parse((window.localStorage.getItem(BOT_CODE) as string) || "[]") as string[];
}

export function getLocalAccessToken(code: string) {
  return window.localStorage.getItem(`${STORAGE_ACCESS_TOKEN}_${code}`);
}

export function setLocalAccessToken(code: string, accessToken: string) {
  window.localStorage.setItem(`${STORAGE_ACCESS_TOKEN}_${code}`, accessToken);
}

export function getLocalConversationId(code: string) {
  return window.localStorage.getItem(`${STORAGE_CONVERSATION_ID}_${code}`);
}

export function setLocalConversationId(code: string, conversationId: string) {
  window.localStorage.setItem(`${STORAGE_CONVERSATION_ID}_${code}`, conversationId);
}

export function setVideoPopuped(code: string, switchPopup: string) {
  window.localStorage.setItem(`${VIDEO_POPUP_SWITCH}_${code}`, switchPopup);
}

export function setBotWelcomeMsgRenderTs(code: string, ts: number) {
  window.localStorage.setItem(`${BOT_WELCOME_MSG_RENDER_TS}_${code}`, JSON.stringify(ts));
}

export function getBotWelcomeMsgRenderTs(code: string) {
  return window.localStorage.getItem(`${BOT_WELCOME_MSG_RENDER_TS}_${code}`);
}

export function setBotBubbleMsgRenderTs(code: string, ts: number) {
  window.localStorage.setItem(`${BOT_BUBBLE_MSG_RENDER_TS}_${code}`, JSON.stringify(ts));
}

export function getBotBubbleMsgRenderTs(code: string) {
  return window.localStorage.getItem(`${BOT_BUBBLE_MSG_RENDER_TS}_${code}`);
}

export function setBotPosition(code: string, pos: Partial<Record<"right" | "bottom", number>>) {
  window.localStorage.setItem(`${BOT_POS}_${code}`, JSON.stringify(pos));
}

export function getBotPosition(code: string): Partial<Record<"right" | "bottom", number>> {
  return JSON.parse(localStorage.getItem(`${BOT_POS}_${code}`) ?? "{}");
}
