import { FunctionComponent } from "preact";
import { useState } from "preact/hooks";
import UIKitButton from "~/components/UIKit/Button";
import Form from "~/components/UIKit/Form";
import Input from "~/components/UIKit/Input";
import Tabs from "~/components/UIKit/Tabs";

interface OrderFormProps {
  onSubmit: (values: any) => void;
}

interface TrackOrderTabsProps {
  onSubmit: (values: any) => void;
}

const OrderForm: FunctionComponent<OrderFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Form.Item name="orderNumber">
        <Input placeholder="Order Number" />
      </Form.Item>
      <Form.Item name="email">
        <Input placeholder="Email" />
      </Form.Item>
      <UIKitButton htmlType="submit">Track</UIKitButton>
    </Form>
  );
};

const TrackingForm: FunctionComponent<OrderFormProps> = ({ onSubmit }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Form.Item name="trackingNumber">
        <Input placeholder="Tracking Number" />
      </Form.Item>

      <UIKitButton htmlType="submit">Track</UIKitButton>
    </Form>
  );
};

const TrackOrderTabs: FunctionComponent<TrackOrderTabsProps> = ({ onSubmit }) => {
  return (
    <>
      <div className="tp-express-card-title">Track your order</div>
      <div className="tp-express-card-content track-order">
        <Tabs
          items={[
            {
              key: "order-number",
              label: "Order Number",
              children: <OrderForm onSubmit={onSubmit} />,
            },
            {
              key: "tracking-number",
              label: "Tracking Number",
              children: <TrackingForm onSubmit={onSubmit} />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default TrackOrderTabs;
