import { FunctionComponent } from "preact";

import "./styles.scss";

export interface IInputProps {
  value?: string;
  placeholder?: string;
  defaultValue?: string;

  onChange?: (value: string) => void;
}

const Input: FunctionComponent<IInputProps> = ({ onChange, ...props }) => {
  return (
    <input className="tp-input" {...props} onChange={(e) => onChange?.(e.currentTarget.value)} />
  );
};

export default Input;
