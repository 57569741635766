import { RefObject } from "preact/compat";
import { Message } from "~/types";
import MessageItem from "./MessageItem";
import React from "preact/compat";
import BotLoading from "../Loading";
import { useCustomizeStore } from "~/stores";
import FetchMore from "./MessageItem/FetchMore";

import "./index.scss";

export interface MessageListRef {
  // 定义 ref 对象的方法或属性
  scrollToBottom: () => void;
}

interface MessageListProps {
  messages: Message[];
  messageListDOMRef?: RefObject<HTMLDivElement>;
  sendMessage: (text: string) => void;
  sendTextMessage: (text: string) => void;
  ready: boolean;
  ifHasMoreMessage: boolean;
  fetchMoreMessages: () => void;
  fetchHistoryPending: boolean;
}

const MessageList = React.forwardRef<HTMLDivElement, MessageListProps>(
  (
    {
      messages,
      ready,
      messageListDOMRef,
      sendMessage,
      sendTextMessage,
      ifHasMoreMessage,
      fetchMoreMessages,
      fetchHistoryPending,
    },
    ref
  ) => {
    const {
      customize: { avatar },
    } = useCustomizeStore();

    if (!ready) {
      return <BotLoading />;
    }

    return (
      <div className="bot-message-list tp-scroll-bar" ref={messageListDOMRef}>
        <FetchMore
          ifHasMoreMessage={ifHasMoreMessage}
          fetchMoreMessages={fetchMoreMessages}
          fetchHistoryPending={fetchHistoryPending}
        />
        {messages.map((message, index) => (
          <MessageItem
            avatar={avatar}
            message={message}
            sendMessage={sendMessage}
            sendTextMessage={sendTextMessage}
            key={index}
          />
        ))}
      </div>
    );
  }
);

export default MessageList;
