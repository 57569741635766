import Icon from "@ant-design/icons";
import classnames from "classnames";
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  type Ref,
  type RefObject,
  type PropsWithChildren,
  useMemo,
  memo,
  useEffect,
  CSSProperties,
} from "preact/compat";
import { createStore } from "zustand";
import arrowSVG from "~/assets/icons/arrow_01.svg?react";
import useMessage from "~/hooks/message/useMessage";
import useAliasData from "~/hooks/useAliasData";
import useModeClassName from "~/hooks/useModeClassName";
import {
  botStore,
  userStore,
  customizeStore,
  messageStore,
  Store,
  StoreContext,
  useBotStore,
  useCustomizeStore,
  useUserStore,
} from "~/stores";
import BotEditor from "../BotEditor";
import CompareBar from "../CompareBar";
import BotHeader from "../Header";
import MessageList from "../MessageList";
import PoweredBy from "../PoweredBy";

import ErrorBoundary from "../ErrorBoundary";
import LoadingSkeleton from "../LoadingSkeleton";
import BotInitError from "./BotInitError";

import useIntegrationConfig from "~/hooks/useIntegrationConfig";
import CompareProductModal from "../CompareProduct/CompareProductModal";
import BotBubble from "../BotBubble";
import useCustomElement, { type CustomMethods } from "~/hooks/useCustomElement";
import {
  BotConfigProps,
  generateStyleVariables,
  propsEqual,
  validateProps,
} from "~/utils/widgetConfig";
import useReportBotInstalled from "~/hooks/useReportBotInstalled";

import "./styles.scss";

const AvailableBot = () => {
  const {
    customize: { brandColor, botName, defaultMessageSetting, logoRemoved },
  } = useCustomizeStore();
  const {
    info: { tenantId },
  } = useUserStore();
  const messageListDOMRef = useRef<HTMLDivElement>(null);
  const modeClassName = useModeClassName();

  useReportBotInstalled();

  // FIXME: 当前是根据 tenantId 是否存在来判断 bot 是否准备好
  // const botConfigReady = Boolean(tenantId);
  const botConfigReady = true;

  const {
    messageList,
    sendTextMessage,
    sendMessage,
    ifHasMoreMessage,
    fetchHistoryPending,
    stopReceive,
    fetchMoreMessages,
    scrollToBottom,
    sendComparisionMessage,
  } = useMessage({
    tenantId: tenantId as string,
    messageListDOMRef,
  });

  return (
    <div className={classnames("bot-core", { ...modeClassName })}>
      <BotHeader name={botName} color={brandColor} />

      <div className="bot-message-render-box tp-scroll-bar">
        {/* <div className="more"> */}
        {/* {ifHasMoreMessage ? <span onClick={fetchMoreMessages}>fetch more</span> : <span></span>} */}
        {/* <div className="line left-line"></div> */}
        {/* <span onClick={fetchMoreMessages}>new messages</span> */}
        {/* <div className="line right-line"></div> */}
        {/* </div> */}

        <MessageList
          messages={messageList}
          messageListDOMRef={messageListDOMRef}
          sendMessage={sendMessage}
          sendTextMessage={sendTextMessage}
          ready={botConfigReady}
          ifHasMoreMessage={ifHasMoreMessage}
          fetchMoreMessages={fetchMoreMessages}
          fetchHistoryPending={fetchHistoryPending}
        />

        <div className="downward-box hide" onClick={scrollToBottom}>
          <Icon component={arrowSVG} />
        </div>
      </div>

      <div className={classnames("bottom-area", { ...modeClassName })}>
        <CompareBar sendComparisionMessage={sendComparisionMessage} />

        <BotEditor
          sendTextMessage={sendTextMessage}
          placeholder={defaultMessageSetting.placeholder || ""}
          brandColor={brandColor}
          stopReceive={stopReceive}
          scrollToBottom={scrollToBottom}
        />

        {!logoRemoved && <PoweredBy />}
      </div>
    </div>
  );
};

const Bot = memo(
  forwardRef((props: BotConfigProps, ref: Ref<CustomMethods>) => {
    const validProps = useMemo(() => validateProps(props), [props]);
    const { compareDialogVisible, integrationConfig, botPosition } = useBotStore();

    useIntegrationConfig(validProps);
    const { loading, error } = useAliasData(validProps);

    const modeClassName = useModeClassName();

    const { popupHidden } = useCustomElement({
      ...validProps,
      botLoading: loading,
      rootRef: ref,
    });
    const {
      customize: { brandColor },
    } = useCustomizeStore();

    const modeClass = useMemo(
      () => classnames("app-bot-box", { ...modeClassName, hide: compareDialogVisible }),
      [modeClassName, compareDialogVisible]
    );
    const wrapperStyle: CSSProperties = useMemo(
      () => ({
        pointerEvents: popupHidden ? "none" : "auto",
        visibility: popupHidden ? "hidden" : "visible",
      }),
      [popupHidden]
    );

    if (error) {
      return <BotInitError forceHide={validProps.hideLoadingNError} />;
    }

    if (loading) {
      return <LoadingSkeleton forceHide={validProps.hideLoadingNError} />;
    }

    const renderBot = () => (
      <ErrorBoundary component={<BotInitError />}>
        <AvailableBot />
      </ErrorBoundary>
    );

    return (
      <div style={{ height: "100%", ...generateStyleVariables(validProps, brandColor) }}>
        {integrationConfig.isWidget ? (
          <div
            className={modeClass}
            style={{
              width: popupHidden ? "0" : "auto",
              height: popupHidden ? "0" : "auto",
              ...(Object.keys(botPosition).length > 0 ? botPosition : {}),
            }}
          >
            <div className="widget-container" style={wrapperStyle}>
              {renderBot()}
            </div>
            <BotBubble draggable={validProps.draggable as boolean} />
          </div>
        ) : (
          <div className={modeClass} style={wrapperStyle}>
            {renderBot()}
          </div>
        )}
        {compareDialogVisible && <CompareProductModal />}
      </div>
    );
  }),
  propsEqual
);

export default forwardRef((props: PropsWithChildren<BotConfigProps>, ref: Ref<CustomMethods>) => {
  const storeRef = useRef<Store | null>(null);
  if (!storeRef.current) {
    storeRef.current = {
      bot: createStore(botStore),
      user: createStore(userStore),
      customize: createStore(customizeStore),
      message: createStore(messageStore),
    };
  }

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
    document.head.appendChild(meta);
  }, []);

  useImperativeHandle(ref, () =>
    (ref as RefObject<CustomMethods>)?.current
      ? { ...(ref as RefObject<CustomMethods>).current }
      : {}
  );

  return (
    <StoreContext.Provider value={storeRef.current}>
      <Bot ref={ref} {...props} />
    </StoreContext.Provider>
  );
});
