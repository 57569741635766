import request from "~/utils/request";

export const getAgentBotConfig = (agentCode: string) => request(`/agents/code/${agentCode}`);

// 注意：调用这个接口，会创建一个新访客
export const createUser = (botCode: string) =>
  request({
    url: `/user/token`,
    params: {
      botCode,
      agentCode: botCode,
    },
  });
