class FormInstance {
  values: any;

  constructor() {
    this.values = {};
  }

  setValue(key: string, value: any) {
    this.values[key] = value;
  }

  getValue(key: string) {
    return this.values[key];
  }

  setValues(values: any) {
    this.values = { ...this.values, ...values };
  }

  getValues() {
    return this.values;
  }
}

export default FormInstance;
