import { useCustomizeStore } from "~/stores";
import "./index.scss";

// 可以用 can you find me a human customer service? 这个词来测试
export default function ContactCard() {
  const {
    customize: { customerContact },
  } = useCustomizeStore();

  function onLinkClick(key: string, value: string) {
    if (key === "phone") {
      window.open(`tel:${value}`);
    } else if (key === "email") {
      window.open(`mailto:${value}`)?.focus();
    } else {
      window.open(value);
    }
  }

  const { primaryArray, otherArray } = format(customerContact);

  if (!customerContact) {
    return (
      <div className="contact-card-component">
        <span>Human support contact wil be added soon</span>
      </div>
    );
  }

  return (
    <div className="contact-card-component">
      <div className="label">You can contact us through the following ways</div>
      {/* 对 customerContact 对象 进行 key value 变量 */}
      {primaryArray.map((item, index) => (
        <div key={index} className="contact-item">
          <div className="contact-item-label">{item.type}</div>
          <div className="contact-item-value" onClick={() => onLinkClick(item.type, item.value)}>
            {item.value}
          </div>
        </div>
      ))}
      {otherArray.map((item, index) => (
        <div key={index} className="contact-item">
          <div className="contact-item-label">{item.type}</div>
          <div className="contact-item-value" onClick={() => onLinkClick(item.type, item.value)}>
            {item.value}
          </div>
        </div>
      ))}
    </div>
  );
}

function format(customerContact: Record<string, string>) {
  // 指定的顺序
  const primaryOrder = ["email", "phone", "linkedIn", "whatsApp"];
  const otherOrder = ["other"];

  let primaryArray: { type: string; value: string }[] = [];
  let otherArray: { type: string; value: string }[] = [];

  if (customerContact) {
    // 按指定顺序生成主数组
    primaryArray = primaryOrder
      .map((key) => ({
        type: key,
        value: customerContact[key],
      }))
      .filter((item) => item.value);

    otherArray = otherOrder
      .map((key) => ({
        type: key,
        value: customerContact[key],
      }))
      .filter((item) => item.value);
  }

  return {
    primaryArray,
    otherArray,
  };
}
