import { FunctionComponent, ReactNode, useEffect, useMemo } from "preact/compat";
import { FromContext } from "./FormContext";
import FormInstance from "./FormInstance";
import FromItem from "./FormItem";

import "./styles.scss";

interface IFormProps {
  form: FormInstance;
  children?: ReactNode;
  initialValues?: any;

  onSubmit?: (values: any) => void;
}

const Form: FunctionComponent<IFormProps> & {
  useForm: () => [FormInstance];
  Item: typeof FromItem;
} = (props) => {
  useMemo(() => {
    props.form.setValues(props.initialValues);
  }, []);

  return (
    <form
      className="tp-form"
      onSubmit={(e) => {
        e.preventDefault();

        props.onSubmit?.(props.form.getValues());
      }}
    >
      <FromContext.Provider value={{ formInstance: props.form }}>
        {props.children}
      </FromContext.Provider>
    </form>
  );
};

Form.useForm = () => {
  const store = useMemo(() => new FormInstance(), []);

  return [store];
};

Form.Item = FromItem;

export default Form;
