import { type StateCreator } from "zustand";

export interface UserInfo {
  tenantId: string;
  agentId: string;
  tenantUnitId: string;
}

export interface UserStore {
  info: UserInfo;
  updateInfo: (info: UserInfo) => void;
  clearInfo: () => void;
}

const defaultInfo = {
  tenantId: "",
  agentId: "",
  tenantUnitId: "",
};

const userStore: StateCreator<UserStore> = (set) => ({
  info: defaultInfo,

  updateInfo: (info: UserInfo) => {
    set((state) => ({ ...state, info }));
  },

  clearInfo: () => {
    set(() => ({
      info: defaultInfo,
    }));
  },
});

export default userStore;
