import Icon from "@ant-design/icons";
import classnames from "classnames";
import { FunctionComponent, type TargetedEvent, useRef, useState } from "preact/compat";
import { useSearch } from "wouter-preact";
import sendSVG from "~/assets/icons/send.svg?react";
import stopSVG from "~/assets/icons/stop.svg?react";
import { MessageState, MessageType } from "~/types";
import useModeClassName from "~/hooks/useModeClassName";
import { useMessageStore } from "~/stores";

import "./styles.scss";

interface IProps {
  sendTextMessage: (text: string) => void;
  stopReceive: () => void;
  placeholder: string;
  brandColor: string;
  scrollToBottom: () => void;
}

const BotEditor: FunctionComponent<IProps> = ({
  sendTextMessage,
  placeholder = "Type a message...",
  brandColor,
  stopReceive,
  scrollToBottom,
}) => {
  const [text, setText] = useState("");
  const { messageState, appendMessage } = useMessageStore();
  const textareaDOMRef = useRef<HTMLTextAreaElement>(null);
  const searchParams = useSearch();
  const inputNotAllowed = new URLSearchParams(searchParams).get("inputNotAllowed");
  const modeClassName = useModeClassName();

  const handleSendMessage = () => {
    console.log(text, "text");
    if (!text.trim() || messageState !== MessageState.AVAILABLE) {
      return;
    }

    sendTextMessage(text);
    resetTextarea();
    // scrollToBottom();
  };

  // 当用户按下回车键时，触发此函数
  const handleInputKeyDown = (event: KeyboardEvent) => {
    // 处理 shift + enter 的换行
    if (event.key === "Enter" && event.shiftKey) {
      const target = event.target as HTMLTextAreaElement;
      const start = target.selectionStart;
      const end = target.selectionEnd;
      setText((prev) => prev.substring(0, start) + "\n" + prev.substring(end));
      setTimeout(() => {
        target.selectionStart = target.selectionEnd = start + 1;
      }, 0);

      // 开始换行
      event.preventDefault();
      return;
    }

    if (event.key === "Enter") {
      event.preventDefault(); // 禁止 enter 之后的默认换行
      handleSendMessage();
    }
  };

  const handleAction = () => {
    if (messageState === MessageState.SENDWAITING || messageState === MessageState.SENDING) {
      // 如果当前状态是等待中，那么调用 sendTextMessage 函数，并传入空字符串
      stopReceive();
    } else {
      // 否则，调用 handleSendMessage 函数
      handleSendMessage();
    }
    resetTextarea();
  };

  const resetTextarea = () => {
    setText("");
  };

  const handleInputChange = (event: TargetedEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  const handleTrackOrder = () => {
    appendMessage({
      type: MessageType.PACKAGE_TRACKING,
      metadata: {},
      isBot: true,
    });

    setTimeout(scrollToBottom);
  };

  return (
    <div className="bot-editor">
      <div className="bot-editor-tools-bar">
        <div className="tool-item" onClick={handleTrackOrder}>
          Track Order
        </div>
      </div>

      <div className={classnames("bot-editor-box", { ...modeClassName })}>
        <textarea
          onChange={handleInputChange}
          autoFocus={false}
          style={{ cursor: inputNotAllowed ? "not-allowed" : "auto" }}
          value={text}
          disabled={inputNotAllowed === "true"}
          placeholder={placeholder}
          onKeyDown={handleInputKeyDown}
          ref={textareaDOMRef}
        />
        <div className="action" onClick={handleAction}>
          {messageState === MessageState.SENDWAITING || messageState === MessageState.SENDING ? (
            <Icon component={stopSVG} style={{ color: brandColor }} />
          ) : (
            <Icon component={sendSVG} style={{ color: brandColor }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BotEditor;
